import Swiper, { Navigation, Pagination } from 'swiper';
// import ScrollOut from 'scroll-out';

document.addEventListener('DOMContentLoaded', () => {

    // Mobile navigation
    var nav = document.querySelector('.mobile');
    var navTrigger = document.querySelector('.js-mobile-btn');
    var navPanel = document.querySelectorAll('.mobile-panel');
    var navPanelTriggers = document.querySelectorAll('.js-mobile-panel-btn');
    
    if(navTrigger){
        bindEvent(navTrigger, 'click', function() {
            navTrigger.classList.toggle('burger-icon--active');
            nav.classList.add('transition');
            nav.classList.toggle('active');
            document.body.classList.toggle('overflow-hidden');
        }, false);
    }
    
    if (navPanelTriggers) {
        for (let i = 0, l = navPanelTriggers.length; i < l; i += 1) {
            navPanelTriggers[i].addEventListener('click', (event) => {
                const panelId = event.currentTarget.dataset.panelId;
                const panel = document.getElementById(`mobile-panel-${panelId}`);
                panel.classList.toggle('active');
            });
        }
    }

    // Mobile search
    var searchTrigger = document.querySelector('.js-search-btn');
    var search = document.querySelector('.mobile-search');

    if(searchTrigger){
        bindEvent(searchTrigger, 'click', function() {
            search.classList.toggle('active');
        }, false);
    }

    // Mega menu modal
    var megaTriggers = document.querySelectorAll('.main-nav__item--children');
    if (megaTriggers) {
        for (let i = 0, l = megaTriggers.length; i < l; i += 1) {
            megaTriggers[i].addEventListener('mouseover', (event) => {
                document.body.classList.add('modal-open');
            });
            megaTriggers[i].addEventListener('mouseout', (event) => {
                document.body.classList.remove('modal-open');
            });
        }
    }

    // Swiper
    var swiper = new Swiper(".product-swiper", {
        modules: [Navigation, Pagination],
        pagination: {
            el: ".swiper-pagination",
            type: 'bullets',
            clickable: true,
        },
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
    });

    // Scroll effects
    // ScrollOut({
    //     targets: '[data-scroll]',
    //     once: 'true'
    // });

    // Bind event helper
    function bindEvent(el, eventName, eventHandler) {
        if (el.addEventListener) {
            el.addEventListener(eventName, eventHandler, false);
        } else if (el.attachEvent) {
            el.attachEvent('on'+eventName, eventHandler);
        }
    }
});